<template>
  <div class="home">
    <div class="app-down" v-if="domainShow">
      <div class="app-down-main">
        <div class="close" @click="domainShow = !domainShow">
          <i class="el-icon-circle-close"></i>
        </div>
        <vue-qr :text="AppDownUrl" :size="200"></vue-qr>
        <div class="text">扫码下载APP</div>
      </div>
    </div>
    <div class="header">
      <div style="position: absolute;
left: 1vw;
top: 1vw;
color: #fff793;
font-size: 14px;
cursor: pointer;" @click="$router.push('/')">回到首页</div>
      <div class="service" @click="domainShow = !domainShow">
        <div class="service_text">
          <img src="../../assets/images/service.png" alt="" />
        </div>
      </div>
      <div class="download">
        <img
            src="../../assets/images/download-ios.png"
            alt=""
            @click="domainShow = !domainShow"
        />
        <img
            class="download_android"
            src="../../assets/images/download-android.png"
            alt=""
            @click="domainShow = !domainShow"
        />
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import vueQr from "vue-qr";
export default {
  components: { vueQr },
  data() {
    return {
      domainShow: false,
    };
  },
  created() {
    this.$api.nft_member.getMemberInfo().then((res) => {
      this.$ku.vuex("memberInfo", res.data);
    });
    this.$api.nft_app.getAppInfo().then((res) => {
      this.$ku.vuex("appInfo", res.data);
    });
  },
  methods: {
    // 跳转页面
    router(url) {
      this.$ku.vuex("userType", url);
      this.$router.push(url);
    },
  },
};
</script>


<style lang="less" scoped>
.header {
  background: url(../../assets/images/header.png) no-repeat;
  background-size: 100%;
  display: flex;
  justify-content: flex-end;
  height: 7.3vw;
  padding-bottom: 3%;
  .service {
    width: 36%;
    cursor: pointer;
    &_text {
      background: url(../../assets/images/lighting_effect2.png) no-repeat;
      background-size: 100%;
      background-position: 0 0;
      height: 100%;
      img {
        width: 12%;
        position: relative;
        top: 5%;
        left: 1%;
      }
    }
  }

  .download {
    width: 6%;
    margin: 0 2%;
    position: relative;
    top: -0.4vw;
    img {
      width: 100%;
      cursor: pointer;
    }
    &_android {
      position: relative;
      top: -0.4vw;
    }
  }
}
</style>
